import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { getDetailContract } from "../../API/ApiContratos";
import { AuthContext } from "../../auth/authContext";
import { CancelarContratoComponent } from "../../Components/CancelarContratoComponent/CancelarContratoComponent";
import { EditContratosEtapaTresComponent } from "../../Components/ContratosEtapaTresComponent/EditContratosEtapaTresComponent";

import { EditContratosEtapaUnoComponent } from "../../Components/ContratosEtapaUnoComponent/EditContratosEtapaUnoComponent";
import { EditarComisionesContratoComponent } from "../../Components/EditarComisionesContratoComponent/EditarComisionesContratoComponent";
import { ListadoDePagosDeContratoComponent } from "../../Components/ListadoDePagosDeContratoComponent/ListadoDePagosDeContratoComponent";
import { ListadoHistoricoiContratoComponent } from "../../Components/ListadoHistoricoiContratoComponent/ListadoHistoricoiContratoComponent";
import { LoadingComponent } from "../../Components/LoadingComponent/LoadingComponent";
import { LugaresDePanteonComponent } from "../../Components/LugaresDePanteonComponent/LugaresDePanteonComponent";
import { TitularesDeContratoComponent } from "../../Components/TitularesDeContratoComponent/TitularesDeContratoComponent";
import { checkPermisosPorUsuarioPorGuidPantalla } from "./../../API/ApiPermisos";
import BitacoraDetalleContratoComponent from "../../Components/BitacoraDetalleContratoComponent/BitacoraDetalleContratoComponent";
import DocumentosDeContratoComponent from "../../Components/DocumentosDeContratoComponent/DocumentosDeContratoComponent";
import PaymentIcon from "@mui/icons-material/Payment";
import { toast } from "react-toastify";
import { postEnviarStringContrato } from "../../API/ApGenerarLigaPago";
import PaidIcon from "@mui/icons-material/Paid";
import ComisionesDosPuntoCeroComponent from "../../Components/ComisionesDosPuntoCeroComponent/ComisionesDosPuntoCeroComponent";

import imagenCancelada from "../../Images/contratocancelado.png";

const guidVista = "6ac593c2-4810-4081-bb29-e80830750a85";

export const DetalleContratoScreenComponent = (props) => {
  const { user, dispatch } = React.useContext(AuthContext);
  const [loading, setLoading] = React.useState(true);
  let { numerocontrato } = useParams();
  let { tababrir } = useParams();

  const [searchParams] = useSearchParams(); // Hook for query params

  const [finalData, setFinalData] = React.useState([]);

  const [contadorActualizar, setContadorActualizar] = React.useState(0);

  const [permisoParaAgregar, setPermisoParaAgregar] = React.useState(false);
  const [permisoParaVisualizar, setPermisoParaVisualizar] =
    React.useState(false);
  const [permisoParaEditar, setPermisoParaEditar] = React.useState(false);
  const [checkPersmissions, setCheckPermissions] = React.useState([]);

  const [contratoInhabilitado, setContratoInhabilitado] = React.useState(false);

  const [ventanaAbierta, setVentanaAbierta] = React.useState(0);

  const [contratoRestructurado, setContratoRestructurado] =
    React.useState(false);

  const [mensajeSinComisionistas, setMensajeSinComisionistas] =
    React.useState(false);

  const [modoSoloLectura, setModoSoloLectura] = React.useState(false);

  React.useEffect(() => {
    let ready = false;

    checkPermisosPorUsuarioPorGuidPantalla(user, guidVista).then(
      (resultado) => {
        if (resultado.status == 200) {
          setCheckPermissions(resultado.data);

          for (const permiso of resultado.data) {
            if (
              permiso.guidFuncion == "704eae79-bee9-42ee-b27c-6e744aea8e65" &&
              permiso.allowed == 1
            ) {
              setPermisoParaEditar(true);
            }
          }
        }
      }
    );

    return () => {
      ready = true;
    };
  }, []);

  React.useEffect(() => {
    let ready = false;

    getDetailContract(user, numerocontrato).then((resultado) => {
      if (resultado.status == 200) {
        if (resultado.data.etapaUno.length == 0) {
          window.open("/404/", "_self");
        } else {
          debugger;
          if (resultado.data.etapaUno[0].status == 2) {
            setModoSoloLectura(true);
          } else {
            setModoSoloLectura(false);
          }

          setFinalData(resultado.data);

          if (resultado.data.etapaUno[0].status == 3) {
            setContratoInhabilitado(true);
          }
          if (resultado.data.etapaUno[0].status == 6) {
            setContratoRestructurado(true);
          }
        }
      } else {
        setFinalData([]);
      }

      setLoading(false);
    });

    return () => {
      ready = true;
    };
  }, []);
  React.useEffect(() => {
    let ready = false;

    getDetailContract(user, numerocontrato).then((resultado) => {
      if (resultado.status == 200) {
        if (resultado.data.etapaUno.length == 0) {
          window.open("/404/", "_self");
        } else {
          setFinalData(resultado.data);
          if (resultado.data.etapaUno[0].status == 3) {
            setContratoInhabilitado(true);
          }
        }
      } else {
        setFinalData([]);
      }

      setLoading(false);
    });

    return () => {
      ready = true;
    };
  }, [contadorActualizar]);

  React.useEffect(() => {
    const queryValue = searchParams.get("selecciontab");
    setVentanaAbierta(queryValue);
  }, [searchParams]);

  return (
    <>
      {loading == true ? (
        <LoadingComponent />
      ) : (
        <>
          {contratoInhabilitado == true ? (
            <div className="alert alert-warning my-3" role="alert">
              Este contrato esta inactivo, verifica en cobranza la situación
            </div>
          ) : null}
          {contratoRestructurado == true ? (
            <div className="alert alert-warning my-3" role="alert">
              Este contrato fue restructurado, verifica en cobranza la situacion
            </div>
          ) : null}

          {mensajeSinComisionistas == true ? (
            <div className="alert alert-danger my-3" role="alert">
              Este contrato no tiene comisionistas asignados,{" "}
              <strong>
                es importante que selecciones al usuario que se encargará de la
                comisión.{" "}
              </strong>
            </div>
          ) : null}

          {finalData.restructura.length > 0 ? (
            <div className="alert alert-info my-3" role="alert">
              Este contrato es una restructuración, los contratos restructurados
              son:{" "}
              {finalData.restructura?.map((contrato) => (
                <>
                  <a
                    rel="noreferrer"
                    target={"_self"}
                    href={"/contrato/" + contrato.idcontratoViejo + "/true"}
                  >
                    ({contrato.serieViejo} - {contrato.noContratoViejo})
                  </a>
                </>
              ))}
            </div>
          ) : null}

          <div className="container-fluid">
            <div className="row">
              <div className="col-12 mt-2 mb-2">
                <h5>Datos generales de contrato</h5>
                <div className="d-flex justify-content-between align-items-center">
                  <p>
                    Contrato: {finalData?.etapaUno?.[0]?.serie}
                    {""}
                    {finalData?.etapaUno?.[0]?.noContrato}
                    <br />
                    Titular: {finalData?.etapaUno?.[0]?.titular}
                  </p>

                  <button
                    type="button"
                    class="btn btn-success"
                    onClick={() => {
                      setLoading(true);
                      postEnviarStringContrato(
                        user,
                        finalData?.etapaUno?.[0].id
                      ).then((resultado) => {
                        if (resultado.status == 200) {
                          setLoading(false);
                          toast.success("Liga enviada correctamente");
                        }
                      });
                    }}
                  >
                    <PaymentIcon /> Enviar url de pago
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{ position: "relative", zIndex: "200", minHeight: "50vh" }}
          >
            {modoSoloLectura == true ? (
              <>
                <img
                  src={imagenCancelada}
                  alt="contrato cancelado"
                  className="imgCancelado"
                />
              </>
            ) : null}
            <ul className="nav nav-tabs mt-2" id="myTab" role="tablist">
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    tababrir == "false" && ventanaAbierta == null
                      ? "active"
                      : null
                  }`}
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home"
                  type="button"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                >
                  Datos Generales de Contrato
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    tababrir == "true" && ventanaAbierta == null
                      ? "active"
                      : null
                  }`}
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile"
                  type="button"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                >
                  Titulares del Contrato
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact"
                  type="button"
                  role="tab"
                  aria-controls="contact"
                  aria-selected="false"
                >
                  Finanzas
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link ${
                    ventanaAbierta == "3" ? "active" : null
                  }`}
                  id="pagos-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pagos"
                  type="button"
                  role="tab"
                  aria-controls="pagos"
                  aria-selected="false"
                >
                  Pagos
                </button>
              </li>
              <li className="nav-item d-none" role="presentation">
                <button
                  className="nav-link"
                  id="comisiones-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#comisiones"
                  type="button"
                  role="tab"
                  aria-controls="comisiones"
                  aria-selected="false"
                >
                  <PaidIcon fontSize="small" className="me-2" />
                  Comisiones 2.0
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="panteon-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#panteon"
                  type="button"
                  role="tab"
                  aria-controls="panteon"
                  aria-selected="false"
                >
                  Espacios
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="historico-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#historico"
                  type="button"
                  role="tab"
                  aria-controls="historico"
                  aria-selected="false"
                >
                  Histórico
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="documentos-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#documentos"
                  type="button"
                  role="tab"
                  aria-controls="documentos"
                  aria-selected="false"
                >
                  Documentos
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="bitacora-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#bitacora"
                  type="button"
                  role="tab"
                  aria-controls="bitacora"
                  aria-selected="false"
                >
                  Bitácora
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className={`tab-pane fade ${
                  tababrir == "false" && ventanaAbierta == null
                    ? "show active"
                    : null
                }  ${modoSoloLectura == true ? "modoSoloLectura" : ""}`}
                id="home"
                role="tabpanel"
                contentEditable={false}
                aria-labelledby="home-tab"
              >
                {finalData.ordenServicio.length > 0 ? (
                  <>
                    <div className="container-fluid mt-3">
                      <div className="row">
                        <div className="col-12 text-end">
                          <button
                            onClick={() => {
                              window.open(
                                `/ordenservicio/${finalData.ordenServicio[0]?.folio}/false`,
                                "_self"
                              );
                            }}
                            className="btn btn-sm btn-info"
                            style={{ cursor: "pointer" }}
                          >
                            {" "}
                            Orden de Servicio:{" "}
                            {finalData.ordenServicio[0]?.folio}{" "}
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                <CancelarContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                />
                <EditContratosEtapaUnoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  tababrir == "true" && ventanaAbierta == null
                    ? "show active"
                    : null
                }  ${modoSoloLectura ? "modoSoloLectura" : ""}`}
                id="profile"
                role="tabpanel"
                contentEditable={false}
                aria-labelledby="profile-tab"
              >
                <TitularesDeContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  modoSoloLectura ? "modoSoloLectura" : ""
                }`}
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
                contentEditable={false}
              >
                <EditContratosEtapaTresComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  permisoParaEditar={permisoParaEditar}
                />
                <hr />

                <EditarComisionesContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                  mensajeSinComisionistas={mensajeSinComisionistas}
                  setMensajeSinComisionistas={setMensajeSinComisionistas}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  ventanaAbierta == "3" ? "show active" : null
                } ${modoSoloLectura ? "modoSoloLectura" : ""}`}
                id="pagos"
                role="tabpanel"
                aria-labelledby="pagos-tab"
                contentEditable={false}
              >
                <ListadoDePagosDeContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>

              <div
                className={`tab-pane fade ${
                  modoSoloLectura ? "modoSoloLectura" : ""
                }`}
                id="comisiones"
                role="tabpanel"
                aria-labelledby="comisiones-tab"
                contentEditable={false}
              >
                <ComisionesDosPuntoCeroComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  setLoading={setLoading}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>

              <div
                className={`tab-pane fade ${
                  modoSoloLectura ? "modoSoloLectura" : ""
                }`}
                id="panteon"
                role="tabpanel"
                aria-labelledby="panteon-tab"
                contentEditable={false}
              >
                <LugaresDePanteonComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  modoSoloLectura ? "modoSoloLectura" : ""
                }`}
                id="historico"
                role="tabpanel"
                aria-labelledby="historico-tab"
                contentEditable={false}
              >
                <ListadoHistoricoiContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  modoSoloLectura ? "modoSoloLectura" : ""
                }`}
                id="bitacora"
                role="tabpanel"
                aria-labelledby="bitacora-tab"
                contentEditable={false}
              >
                <BitacoraDetalleContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>
              <div
                className={`tab-pane fade ${
                  modoSoloLectura ? "modoSoloLectura" : ""
                }`}
                id="documentos"
                role="tabpanel"
                aria-labelledby="documentos-tab"
              >
                <DocumentosDeContratoComponent
                  contrato={numerocontrato}
                  finalData={finalData}
                  contadorActualizar={contadorActualizar}
                  setContadorActualizar={setContadorActualizar}
                  permisoParaEditar={permisoParaEditar}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
